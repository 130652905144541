/**
 * PACKAGE ENTRY
 * export public methods and classes from this file
 *
 * For example:
 * export { default, NamedExport1, NamedExport2 } from './some-file';
 */
import { from } from '@apollo/client';
import fetch from 'cross-fetch';

import { FieldsContainer, FieldsContainerFragment, SysAssetConnectionFragment } from './__generated__/fragments.v2';
import { API_KEY, CLIENT_NAME } from './src/constants';
import { ContentstackField } from './src/types';

import { ApolloLink, HttpLink, HttpLinkConfig, MockLink, onError } from '~/utils/apollo-client';

export * from './src/hooks';
export * from './src/types';

const contentstackErrorLink = onError(({ operation, graphQLErrors, networkError, response }) => {
  console.error(`Contentstack Error: ${operation.operationName}`, { graphQLErrors, response, networkError });
});

export const getContentstackHttpLinkConfig = (environment: string, deliveryToken: string): HttpLinkConfig => ({
  name: CLIENT_NAME,
  link: from([
    contentstackErrorLink,
    new HttpLink({
      uri: `https://graphql.contentstack.com/stacks/${API_KEY}?environment=${environment}`,
      headers: {
        access_token: deliveryToken,
      },
      fetch,
    }),
  ]),
});

export const getContentstackMockLinkConfig = (mockLink: MockLink): HttpLinkConfig => {
  const fallbackHttpLink = getContentstackHttpLinkConfig('dev', 'csaffbef3139b4b24aee2cc24c');
  return {
    name: CLIENT_NAME,
    link: ApolloLink.concat(
      onError(({ networkError, operation, forward }) => {
        if (networkError && networkError.message.includes('No more mocked responses')) {
          const liveResponse = fallbackHttpLink.link.request(operation, forward);
          if (liveResponse) {
            return liveResponse;
          }
        }
        return;
      }),
      mockLink,
    ),
  };
};

export const getImageAssetUrl = (assetConnection?: SysAssetConnectionFragment | null): string =>
  assetConnection?.edges?.[0]?.node?.url ?? '';

export const findFieldValue = (
  fields:
    | FieldsContainerFragment['rte']
    | FieldsContainerFragment['text']
    | (ContentstackField | null)[]
    | null
    | undefined,
  fieldKey: string,
): string => fields?.find(field => field?.key === fieldKey)?.value ?? '';

type LabelPair = ({ key: string | null; label?: string | null; value?: string | null } | null)[] | null;

export const getValueFromKeyValuePair = (key: string, labelPair?: LabelPair | null): string => {
  const pair = labelPair?.find(item => item?.key === key);
  return pair?.label ?? pair?.value ?? '';
};

/**
 * The FieldContainer contentstack field should be used with the set of keys that are expected to be provisioned.
 */
export type FieldsContainerValueResolver<TKey extends string> = (key: TKey) => string;

export const getFieldsContainerTextResolver =
  <TKey extends string>(content?: FieldsContainer | null): FieldsContainerValueResolver<TKey> =>
  key =>
    content?.text?.find(i => i?.key === key)?.value ?? '';

export const getFieldsContainerRteResolver =
  <TKey extends string>(content?: FieldsContainer | null): FieldsContainerValueResolver<TKey> =>
  key =>
    content?.rte?.find(i => i?.key === key)?.value ?? '';

export const getFieldsContainerImageResolver =
  <TKey extends string>(content?: FieldsContainer | null): FieldsContainerValueResolver<TKey> =>
  key =>
    getImageAssetUrl(content?.image?.find(i => i?.key === key)?.imageConnection);
