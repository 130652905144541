import { FinancialAccountType } from '~/__generated__';
import { HoldingsData } from '~/components/AccountDetailsHoldings/types';
import { ContentOptions } from '~/utils/contentstack';

export interface ExtractedBrokerageData {
  accountBalance: number;
  accountNumber: number;
  accountType: FinancialAccountType;
  currencyCode: string;
  financialInstitution: string;
}

export type Props = {
  content?: ConnectBrokerageAccountModalContent;
  contentOptions: ContentOptions;
  data?: ConnectBrokerageAccountAutoFetchingData;
  enabledAccountTypes: FinancialAccountType[];
  isSubmitted: boolean;
  onSuccessfulSubmitCallback?: ({
    accountBalance,
    accountNumber,
    accountType,
    financialInstitution,
  }: ExtractedBrokerageData) => void;
};

export interface ConnectBrokerageAccountModalContent {
  accountTypeLabels: { label: string; value: FinancialAccountType }[];
  ctas: {
    back: string;
    cancel: string;
    connectAccount: string;
    dndFileuploader: string;
    editAccount: string;
    missingFieldsFix: string;
    next: string;
  };
  errors: {
    accountType: string;
    balance: string;
    financialInstitution: string;
  };
  images: {
    aiDataImage: string;
    bankAccountIcon: string;
  };
  labels: {
    accountNumber: string;
    accountNumberPattern: string;
    accountType: string;
    aiDataHeading: string;
    aiDataMessage: string;
    balance: string;
    editAccountHeading: string;
    editAccountModalTitle: string;
    extractedAccountsHeading: string;
    extractedAccountsMessage: string;
    financialInstitution: string;
    missingFieldsWarningMessage: string;
    passwordHeading: string;
    passwordLabel: string;
    selectAccount: string;
    title: string;
    unsupportedAccountTypeWarningMessage: string;
  };
}

export enum ConnectBrokerageAccountSteps {
  DOCUMENT_UPLOADER = 'DOCUMENT_UPLOADER',
  ENTER_PASSWORD = 'ENTER_PASSWORD',
  EXTRACTED_ACCOUNTS_LIST = 'EXTRACTED_ACCOUNTS_LIST',
}

export interface CustomFile extends File {
  filePassword?: string;
}

export enum SupportedFileTypes {
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  PNG = 'image/png',
}

export interface AccountDetails {
  accountName?: string | null;
  accountNumber?: string | null;
  accountType?: FinancialAccountType | null;
  balance?: string | null;
  currency?: string | null;
  financialInstitution?: string | null;
  holdings?: HoldingsData[];
  maskedAccountNumber?: string;
  selected: boolean;
}

export interface ConnectBrokerageAccountAutoFetchingData {
  accountDetails?: AccountDetails[];
  filePath?: string;
}

export enum NotificationMessageType {
  AUTO_FETCH_FAILED = 'auto_fetch_failed',
  AUTO_FETCH_SUCCESS = 'auto_fetch_success',
  FILE_TYPE = 'file_type_error',
  MAX_FILE = 'max_file',
  MAX_FILE_SIZE = 'file_size_error',
  PASSWORD_MISSING = 'incorrect_password',
  SUCCESS_UPLOAD = 'successfully_upload_document',
  // TODO: Remove Duplicate ENUM values for PASSWORD_MISSING and WRONG_PDF_PASSWORD
  // eslint-disable-next-line
  WRONG_PDF_PASSWORD = 'incorrect_password',
}

export interface NotificationMessage {
  config?: { [key: string]: any };
  message: string;
  severity: 'success' | 'error';
}
