import uniqueId from 'lodash.uniqueid';
import * as React from 'react';
import { useState } from 'react';
import { isIE } from 'react-device-detect';

import { MultiSelectDropdownRenderValue } from './MultiSelectDropdownRenderValue';
import { DropdownItem, DropdownVariant, Props } from './types';

import { Drawer } from '~/components/ui/Drawer';
import { HelperTextContent } from '~/components/ui/HelperTextContent';
import {
  Box,
  Checkbox,
  CheckIcon,
  ExpandMoreIcon,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  useTheme,
} from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';
import { useIsMediumScreen } from '~/utils/responsiveness';
import { getSxPropsArray } from '~/utils/theme';

const formVariants: Record<DropdownVariant, SelectProps['variant']> = {
  accountDetails: 'standard',
  filled: 'filled',
  madlib: 'standard',
  outlined: 'outlined',
  outlinedClassic: 'outlined',
  standard: 'standard',
  accountPerformance: 'outlined',
};

export const Dropdown: React.FC<Props> = ({
  DrawerProps,
  ariaLabel,
  dataQa = 'dropdown',
  description,
  disabled,
  displayEmpty,
  error,
  id,
  inputLabelProps,
  items,
  label,
  multiple,
  native,
  placeholder,
  placeholderValue = '',
  onChange,
  onOpen,
  onClose,
  multiSelectRenderValueProps,
  required,
  tooltipText,
  typographyVariant,
  value,
  variant = 'outlinedClassic',
  variantMenu,
  width = 'auto',
  inputProps,
  ...rest
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const isMobile = useIsMediumScreen();
  const {
    typography,
    sfDropdown: { root: sfDropdownStylesRoot, styles: sfDropdownStyles, typographyVariants: sfDropdownTypography },
  } = useTheme();
  const dropdownId = id ?? uniqueId(`${dataQa}-`);
  const dropdownLabelId = `${dropdownId}-label`;

  const isOutlinedClassic = variant === 'outlinedClassic';
  const isMadlib = variant === 'madlib';
  const isPerformance = variant === 'accountPerformance';
  const isAccountDetails = variant === 'accountDetails';
  const useNativeDropdown = native || isIE;
  const useDrawerDropdown = !native && isMobile;

  let dropdownInputStyles = {};
  const { sx: inputStyles, ...restOfInputProps } = inputProps ?? {};

  // TODO: Do not set inputFontSize and inputFontWeight based on variant but use typographyVariant. madlib variant still valid as other dropdownProps are included
  if (isMadlib) {
    const madlibDropdownStyles = sfDropdownStyles.madlib?.dropdownProps || {};

    const fontStyles = {
      fontSize: typography[sfDropdownTypography?.madlibInput || 'h3'].fontSize,
      fontWeight: typography[sfDropdownTypography?.madlibInput || 'h3'].fontWeight,
    };

    dropdownInputStyles = {
      ...dropdownInputStyles,
      ...fontStyles,
      ...madlibDropdownStyles,
    };
  }

  // TODO: Deprecate accountDetails variant and pass in typography variant h3 (DA2-5459)
  if (isAccountDetails) {
    dropdownInputStyles = { ...dropdownInputStyles, ...typography.h3 };
  }

  if (typographyVariant) {
    dropdownInputStyles = { ...dropdownInputStyles, ...typography[typographyVariant] };
  }

  const drawerActionItems = items.map(item =>
    item.type === 'list-item-header'
      ? item
      : {
          ...item,
          description: item.description ? <Typography variant="caption">{item.description}</Typography> : undefined,
          isChecked: value === item.value,
          isDisabled: item.disabled,
          label: <Typography variant={isMadlib ? 'body1' : 'body2'}>{item.label}</Typography>,
          onClick: () => {
            onChange?.({ target: { value: item.value } });
            item.onClick?.();
            setDrawerOpen(false);
          },
        },
  );

  const getMenuItem = (item: DropdownItem) => {
    const isPlaceholder = item.value === placeholderValue;

    if (useNativeDropdown) {
      return (
        <option
          data-qa={`${dataQa}-menu-item`}
          disabled={isPlaceholder}
          key={item.value}
          style={{
            ...(isPlaceholder ? { display: 'none', color: sfDropdownStyles.menuItemPlaceholderColor } : {}),
          }}
          title={item.description}
          value={item.value}
        >
          {item.label}
        </option>
      );
    }

    const madlibsSx = {
      whiteSpace: 'break-spaces',
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
      '.MenuItem-label': {
        color: value === item.value ? 'primary.main' : 'text.primary',
      },
    };

    const performanceSx = {
      borderBottom: '1px solid',
      borderColor: 'grey.300',
      py: 3,
      '&.Mui-selected': { color: 'primary.main', borderColor: 'grey.300', backgroundColor: 'transparent' },
      '&.Mui-selected .MenuItem-icon': { color: 'primary.main', mt: 0.25, mr: 0.5 },
      '&.Mui-selected .MuiTypography-root.MuiTypography-body1': { color: 'primary.main' },
    };

    return (
      <MenuItem
        data-qa={`${dataQa}-menu-item`}
        disabled={isPlaceholder || item.disabled}
        key={item.value}
        onClick={item.onClick}
        selected={value === item.value}
        sx={[
          isPlaceholder && {
            display: 'none',
          },
          isMadlib && madlibsSx,
          isPerformance && performanceSx,
        ]}
        value={item.value}
      >
        <Grid container>
          {(isMadlib || isPerformance) && value === item.value && (
            <CheckIcon
              className="MenuItem-icon"
              fontSize="small"
              sx={{
                color: sfDropdownStyles.madlib?.selectedItemColor,
              }}
            />
          )}
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <Grid container direction="column" item xs>
            <Grid display="flex" item>
              {multiple ? <Checkbox checked={Array.isArray(value) ? value.includes(item.value) : undefined} /> : null}
              <Typography alignSelf="center" className="MenuItem-label" variant="body2">
                {item.label}
              </Typography>
            </Grid>
            {item.description && (
              <Grid item>
                <Typography className="MenuItem-description" sx={{ display: 'none' }} variant="caption">
                  {item.description}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </MenuItem>
    );
  };

  const getRenderValue = (selected: string | number | (string | number)[]) => {
    const listItems = items.filter((item): item is DropdownItem => !item.type || item.type === 'list-item');

    return (
      multiSelectRenderValueProps && (
        <MultiSelectDropdownRenderValue
          items={listItems}
          multiSelectRenderValueProps={multiSelectRenderValueProps}
          onChange={onChange}
          placeholder={placeholder}
          selected={selected}
        />
      )
    );
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        width,
        '.MuiSelect-icon': { color: sfDropdownStyles.iconColor },
        ...sfDropdownStylesRoot,
        ...(isMadlib || isAccountDetails ? sfDropdownStyles.madlib?.root : {}),
      }}
    >
      <FormControl
        data-qa={dataQa}
        disabled={disabled}
        error={error}
        fullWidth={width !== 'auto'}
        required={required}
        variant={formVariants[variant]}
      >
        {label && (
          <Box data-qa={`${dataQa}-label`} sx={{ alignItems: 'baseline', display: 'flex' }}>
            <InputLabel
              aria-label={ariaLabel}
              htmlFor={dropdownId}
              id={dropdownLabelId}
              shrink={displayEmpty || undefined}
              sx={[
                ...getSxPropsArray(sfDropdownStyles.outlinedClassic?.inputLabel),
                ...getSxPropsArray(inputLabelProps?.sx),
              ]}
            >
              {/* TODO: DA2-2406: Refactor dropdowns to use RteContent for tooltips and remove tooltipText prop */}
              {typeof label === 'string' ? <RteContent data={label} /> : label}
            </InputLabel>
            {tooltipText && (
              <Tooltip
                data-qa={`${dataQa}-label-tooltip`}
                placement="bottom"
                tooltipContent={<RteContent data={tooltipText} />}
              />
            )}
          </Box>
        )}
        <Select
          IconComponent={
            isMadlib
              ? () => (
                  <ExpandMoreIcon
                    sx={{
                      color: sfDropdownStyles.madlib?.selectedItemColor,
                      // Position the icon below the trigger element so the full width is clickable
                      position: 'absolute',
                      right: 0,
                      zIndex: -1,
                    }}
                  />
                )
              : undefined
          }
          MenuProps={{
            MenuListProps: {
              sx: [
                { py: 0 },
                {
                  '.MuiMenuItem-root': {
                    '.Mui-selected, :hover, :focus': {
                      '.MenuItem-description': {
                        display: 'block', // make description appear since it's display none by default
                      },
                    },
                  },
                },
                isMadlib && {
                  '.MuiMenuItem-root': {
                    display: 'block',
                    p: 2,
                    '&:hover, &:focus': {
                      '.MenuItem-label': { color: 'primary.main' },
                    },
                  },
                  '.MenuItem-icon': {
                    position: 'absolute',
                    left: 10,
                    top: 20,
                  },
                  '.MenuItem-label': { mx: 3 },
                  '.MenuItem-description': { ml: 3 },
                  '.Mui-selected .MenuItem-icon': { display: 'block' },
                  maxHeight: '550px',
                },
              ],
            },
          }}
          SelectDisplayProps={{
            role: 'combobox',
            style: { display: 'flex', alignItems: 'center', whiteSpace: 'normal' },
          }}
          aria-describedby={error ? `${dropdownId}-helper-text` : undefined}
          aria-haspopup="listbox"
          data-qa={`${dataQa}-select`}
          disabled={disabled}
          displayEmpty={isOutlinedClassic ? true : displayEmpty}
          id={dropdownId}
          inputProps={{
            name: typeof label === 'string' ? label : undefined,
            sx: {
              '.MenuItem-label.MuiTypography-root': {
                overflow: 'hidden',
                ...dropdownInputStyles,
              },
              '&.MuiSelect-select': {
                color: isMadlib || isAccountDetails ? sfDropdownStyles.madlib?.selectedItemColor : undefined,
              },
              '.MenuItem-icon': {
                display: 'none',
              },
              ...inputStyles,
            },
            'aria-disabled': disabled,
            ...restOfInputProps,
          }}
          labelId={label ? dropdownLabelId : undefined}
          multiple={multiple}
          native={useNativeDropdown}
          onChange={e => onChange?.(e)}
          onClose={e => {
            setSelectOpen(false);
            onClose?.(e);
          }}
          onOpen={e => {
            if (!disabled) {
              if (useDrawerDropdown) {
                setDrawerOpen(true);
              } else {
                setSelectOpen(true);
              }
              onOpen?.(e);
            }
          }}
          open={selectOpen}
          renderValue={multiple ? selected => getRenderValue(selected) : undefined}
          value={value}
          {...rest}
        >
          {placeholder
            ? getMenuItem({
                label: placeholder,
                value: placeholderValue,
              })
            : null}
          {items.map((item, index) =>
            item.type === 'list-item-header' ? (
              <ListSubheader key={`header-${index}`}>{item.label}</ListSubheader>
            ) : (
              getMenuItem(item)
            ),
          )}
        </Select>
        <Drawer
          data-qa={`${dataQa}-mobile`}
          menuItems={drawerActionItems}
          onClose={() => setDrawerOpen(false)}
          // This opens for options in mobile view
          // The style added is to remove border bottom from header as there is no title for dropwdown options
          open={drawerOpen}
          sx={sfDropdownStyles.drawerSx}
          {...DrawerProps}
        />
        <HelperTextContent error={error} id={`${dropdownId}-helper-text`} variant={isMadlib ? 'string' : undefined}>
          {description}
        </HelperTextContent>
      </FormControl>
    </Box>
  );
};
