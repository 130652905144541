import {
  AccountSummaryGetDigitalWealthAccounts,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_entityUpdateWorkflow,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_AssetTransfer,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_legalDocuments,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_legalDocuments_signees,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_planUpdateWorkflows,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_allocations,
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_tradingSuspensions,
  AccountSummaryGetDigitalWealthAccountsVariables,
} from './__generated__/query.v2';
import * as getAbandonedAccountsQueries from './getAbandonedAccounts.gql';
import * as queries from './query.gql';

import { TransferFrequency } from '~/__generated__/symphonyTypes.v2';
import {
  GetAbandonedAccounts,
  GetAbandonedAccountsVariables,
} from '~/containers/AccountSummary/symphony/__generated__/getAbandonedAccounts.v2';
import { QueryHookOptions, QueryResult } from '~/utils/apollo-client';
import { useSymphonyQuery } from '~/utils/symphony';

export type Product = AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products;
export type ManagedProduct = AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct;
export type FinancialAccount = AccountSummaryGetDigitalWealthAccounts_client_financialAccounts;
export type AssetClassAllocation =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_targetModelPortfolio_guidance_diversification_assets_allocations;
export type AccountSummaryTradingSuspensions =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_tradingSuspensions;
export type LegalDocument =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_legalDocuments;
export type EntityUpdateWorkflow =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_entityUpdateWorkflow;
export type PlanUpdateWorkflow =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_planUpdateWorkflows;
export type LegalDocumentSignee =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_legalDocuments_signees;
export type { AccountSummaryGetDigitalWealthAccounts };

export const isEntityUpdateWorkflow = (euw: EntityUpdateWorkflow | null): euw is EntityUpdateWorkflow =>
  euw?.__typename === 'EntityUpdateWorkflow';

export const isPlanUpdateWorkflow = (puw: PlanUpdateWorkflow | null): puw is PlanUpdateWorkflow =>
  puw?.__typename === 'PlanUpdateWorkflow';

export type ScheduledTransfer =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers;
export type AssetTransfer =
  AccountSummaryGetDigitalWealthAccounts_client_financialAccounts_products_ManagedProduct_fundingDetails_scheduledTransfers_AssetTransfer;

export const isAssetTransfer = (scheduledTransfer?: ScheduledTransfer | null): scheduledTransfer is AssetTransfer =>
  scheduledTransfer?.__typename === 'AssetTransfer';

export const isOneTime = ({ frequency }: ScheduledTransfer): boolean => frequency === TransferFrequency.ONE_TIME;
export const isRecurring = ({ frequency }: ScheduledTransfer): boolean => frequency !== TransferFrequency.ONE_TIME;

export const useGetDigitalWealthAccounts = (
  options?: QueryHookOptions<AccountSummaryGetDigitalWealthAccounts, AccountSummaryGetDigitalWealthAccountsVariables>,
): QueryResult<AccountSummaryGetDigitalWealthAccounts, AccountSummaryGetDigitalWealthAccountsVariables> => {
  return useSymphonyQuery(queries.AccountSummaryGetDigitalWealthAccounts, options);
};

export const useGetAbandonedAccounts = (
  options?: QueryHookOptions<GetAbandonedAccounts, GetAbandonedAccountsVariables>,
): QueryResult<GetAbandonedAccounts, GetAbandonedAccountsVariables> => {
  return useSymphonyQuery(getAbandonedAccountsQueries.GetAbandonedAccounts, options);
};
