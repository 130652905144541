// DA2-5951: The node Element type casts that it has children and attribs, but they may be undefined.
import HtmlToReact, { ProcessingInstruction, TextNode } from 'html-to-react';
import React, { AnchorHTMLAttributes, ComponentProps, ComponentType, Fragment, ReactNode } from 'react';

import { Box } from '~/components/ui/mui';
import { RteContent } from '~/components/ui/redactor/RteContent';
import { Tooltip } from '~/components/ui/Tooltip';
import { Typography } from '~/components/ui/Typography';

type Variant = ComponentProps<typeof Typography>['variant'];
type VariantMapping = ComponentProps<typeof Typography>['variantMapping'];

enum BodyClass {
  body1 = 'body1',
  body2 = 'body2',
  caption = 'caption',
  overline = 'overline',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
}

const getBodyInstruction = (
  bodyClass: BodyClass,
  variantMapping?: VariantMapping,
  gutterBottom?: boolean,
): ProcessingInstruction => ({
  shouldProcessNode: node => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return node.attribs?.class?.split(' ').includes(bodyClass);
  },
  processNode: (_node, children, index) => {
    const { class: string, ...otherAttribs } = _node.attribs;
    return (
      <Typography
        {...otherAttribs}
        gutterBottom={gutterBottom}
        key={index}
        variant={bodyClass}
        variantMapping={variantMapping}
      >
        {children}
      </Typography>
    );
  },
});

const getDefaultBodyInstruction = (
  variantMapping?: VariantMapping,
  gutterBottom?: boolean,
  variant?: Variant,
): ProcessingInstruction => ({
  shouldProcessNode(node) {
    return node.name === 'p';
  },
  processNode(_node, children, index) {
    const { class: string, ...otherAttribs } = _node.attribs;
    return (
      <Typography
        {...otherAttribs}
        gutterBottom={gutterBottom}
        key={index}
        variant={variant ?? 'body1'}
        variantMapping={variantMapping}
      >
        {children}
      </Typography>
    );
  },
});

/**
 * Only wrap strings without html tag in Typography to set styling if a defaultVariant is provided.
 */
const getStringBodyInstruction = (
  variantMapping?: VariantMapping,
  gutterBottom?: boolean,
  variant?: Variant,
): ProcessingInstruction => ({
  shouldProcessNode: node => {
    return !!variant && node.type === 'text' && node.parent?.type === 'root';
  },
  processNode(node, index) {
    return (
      <Typography gutterBottom={gutterBottom} key={index} variant={variant ?? 'body1'} variantMapping={variantMapping}>
        {(node as unknown as { data: string }).data}
      </Typography>
    );
  },
});

const getHeadingInstruction = (variantMapping?: VariantMapping): ProcessingInstruction => {
  return {
    shouldProcessNode(node) {
      return /^h[123456]$/.test(node.name);
    },
    processNode(node, children, index) {
      const variant = node.name as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
      const { class: string, ...otherAttribs } = node.attribs;
      return (
        <Typography {...otherAttribs} key={index} variant={variant} variantMapping={variantMapping}>
          {children}
        </Typography>
      );
    },
  };
};

const getVariableReplacementInstruction = (config?: Record<string, ReactNode>): ProcessingInstruction => ({
  shouldProcessNode(node) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return node.name === 'span' && node.attribs?.['data-redactor-type'] === 'variable';
  },
  processNode({ children }, _children, index) {
    const childNode = children[0] as TextNode;
    if (childNode.type !== 'text') {
      throw new Error(`Expected child node of type text but got ${childNode.type}`);
    }
    const replacementKey = childNode.nodeValue;
    const replacement = config?.[replacementKey];
    if (!config) {
      console.warn(
        `Invalid config provided for variable replacement.\nExpected key ${replacementKey} to be present in config object, but got undefined`,
      );
      return replacementKey;
    }
    if (replacement === undefined) {
      console.warn(
        `Invalid config provided for variable replacement.\nExpected key ${replacementKey} to be present in config object, but got object with these keys:\n[${Object.keys(
          config,
        ).join(', ')}]`,
      );
      return replacementKey;
    }
    return <Fragment key={index}>{replacement}</Fragment>;
  },
});

const getTooltipReplacementInstruction = (): ProcessingInstruction => ({
  shouldProcessNode(node) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return node.name === 'span' && node.attribs?.['data-redactor-type'] === 'tooltip';
  },
  processNode(node, _children, index) {
    const childNode = node.children[0] as TextNode;
    if (childNode.type !== 'text') {
      throw new Error(`Expected child node of type text but got ${childNode.type}`);
    }
    const tooltipAnchorText = childNode.nodeValue;
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    const tooltipContent = node.attribs?.['data-tooltip-content'];
    const useInfoIcon = node.attribs?.['data-use-info-icon']?.toLowerCase() === 'true';
    const iconPosition = node.attribs?.['data-icon-position'];
    const { class: string, ...otherAttribs } = node.attribs;
    return (
      <Tooltip
        {...otherAttribs}
        anchorText={useInfoIcon ? '' : tooltipAnchorText}
        iconPosition={iconPosition}
        key={index}
        tooltipContent={<RteContent data={tooltipContent} />}
      />
    );
  },
});

/**
 * Redactor uses the <u> tag to underline text, but using text-decoration is more semantically correct.
 * See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/u for more details
 */
const unarticulatedTagInstruction: ProcessingInstruction = {
  shouldProcessNode(node) {
    return node.name === 'u';
  },
  processNode(_node, children, index) {
    return (
      <Box component="span" key={index} sx={{ textDecoration: 'underline' }}>
        {children}
      </Box>
    );
  },
};

const emptyTagInstruction: ProcessingInstruction = {
  shouldProcessNode(node) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return node.name !== 'path' && node.name !== 'br' && node.name !== 'img' && node.children?.length === 0;
  },
  processNode() {
    return '';
  },
};

const strongTagInstruction: ProcessingInstruction = {
  shouldProcessNode(node) {
    return node.name === 'strong';
  },
  processNode(_node, children, index) {
    return <strong key={index}>{children}</strong>;
  },
};

const getAnchorTagInstruction = (
  AnchorComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>,
): ProcessingInstruction => {
  return {
    shouldProcessNode(node) {
      return node.name === 'a';
    },
    processNode(node, children, index) {
      const attributes = node.attribs;
      const href = attributes.href;
      const ariaLabel = attributes['aria-label'];
      const target = attributes.target;
      const rel = attributes.rel;
      const anchorProps = {
        href,
        rel,
        target,
        'aria-label': ariaLabel,
      };
      return AnchorComponent ? (
        <AnchorComponent key={index} {...anchorProps}>
          {children}
        </AnchorComponent>
      ) : (
        <a key={index} {...anchorProps}>
          {children}
        </a>
      );
    },
  };
};

const defaultInstruction: ProcessingInstruction = {
  shouldProcessNode: () => true,
  processNode: new HtmlToReact.ProcessNodeDefinitions(React).processDefaultNode,
};

const getBodyInstructions = (variantMapping?: VariantMapping, gutterBottom?: boolean, variant?: Variant) => [
  ...Object.values(BodyClass).map(className => getBodyInstruction(className, variantMapping, gutterBottom)),
  getDefaultBodyInstruction(variantMapping, gutterBottom, variant),
];

export const htmlToReact = (
  htmlString: string,
  config?: Record<string, string | number | ReactNode>,
  variantMapping?: VariantMapping,
  anchorComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>,
  gutterBottom?: boolean,
  defaultBodyVariant?: Variant,
): ReactNode => {
  const component = new HtmlToReact.Parser().parseWithInstructions(
    htmlString.replace(/(\r?\n|\r)\s+/g, ''),
    () => true,
    [
      emptyTagInstruction,
      ...getBodyInstructions(variantMapping, gutterBottom, defaultBodyVariant),
      getHeadingInstruction(variantMapping),
      unarticulatedTagInstruction,
      getVariableReplacementInstruction(config),
      getTooltipReplacementInstruction(),
      getAnchorTagInstruction(anchorComponent),
      strongTagInstruction,
      getStringBodyInstruction(variantMapping, gutterBottom, defaultBodyVariant),
      defaultInstruction,
    ], // order is not arbitrary
  );
  return Array.isArray(component) ? <>{component.map(el => el)}</> : component;
};
