import { ComponentProps, ReactNode } from 'react';

import { AppliedFilters } from '~/components/AppliedFilters';
import { Content as FilterContent } from '~/components/Filters';
import { FilterConfig, SelectedFilters } from '~/components/Filters/types';
import { SortConfig, TableColumn, TableData } from '~/components/ui/BasicTable';
import { NumberRange } from '~/components/ui/NumberFormatRange';
import { HomeOfficeView } from '~/containers/HomeOfficeDashboard/config';
import { PaginationContext } from '~/utils/types';

export enum MetricsIds {
  AdvisorAccountsClosedInPeriodCount = '01HTQBM3CBDKJA31W807BEAXQZ',
  AdvisorAccountsInProgressCount = '01HRV7BYHC50AC9SA5GYY20WE6',
  AdvisorAccountsOpenedInPeriodCount = '01HTQBK62A2KMFB29D7B894RG3',
  AdvisorActiveAccountsCount = '01HRV7CDBDG039FE5KH46JCMKK',
  AdvisorClosedAccountsCount = '01HRV7CTN8RGCST3Y5HSEQM63F',
  AdvisorTotalAUM = '01HRTZPHYP7RA736V42A2J6NQH',
  ChangeInAdvisorAUM = '01HTQK1H842ZX543DS0HH3735J',
  ChangeInPartnerAUM = '01HTQJWJHWBABVRMT74H6VF9AG',
  PartnerAccountsInProgressCount = '01HR6S8AAVYXGJ87W89Y7HWDV3',
  PartnerActiveAccountsCount = '01HQHX97E4CCZHHBF23SMAHFHR',
  PartnerClosedAccountsCount = '01HR6S66XEQP48D0DH8490PJNT',
  PartnerTotalAUM = '01HR6KY9T6BJJCWSPB2XTB7VBP',
}

export type HomeOfficeColumnKey =
  | 'advisorName'
  | 'contactInformation'
  | 'aumForPeriod'
  | 'accountsOpenedInPeriod'
  | 'accountsClosedInPeriod'
  | 'aumAsOfDate'
  | 'accountsActiveAsOfDate'
  | 'accountsClosedAsOfDate'
  | 'accountsInProgressAsOfDate';

export interface HomeOfficeTableContent {
  nullStateMessage: string;
  repCodeLabel: string;
  tableColumns: TableColumn[];
}

export interface HomeOfficeTableColumnConfig {
  key: HomeOfficeColumnKey;
  sortable: boolean;
  wrappable: boolean;
}

export interface HomeOfficeContent {
  appliedFiltersContent: ComponentProps<typeof AppliedFilters>['content'];
  csvHeader: {
    accountsActiveAsOfDate: string;
    accountsClosedAsOfDate: string;
    accountsClosedInPeriod: string;
    accountsInProgressAsOfDate: string;
    accountsOpenedInPeriod: string;
    advisorName: string;
    aumAsOfDate: string;
    aumForPeriod: string;
    changeInAumForPeriod: string;
    email: string;
    phone: string;
    repCode: string;
  };
  downloadCsv: string;
  filterContent: FilterContent;
  homeOfficeTableFilterOptionsContent: Record<HomeOfficeFilterOptions, string>;
  recordsPerPageLabel: string;
  search: {
    emptyResults: string;
    placeholder: string;
    repPrefix: string;
  };
  table: HomeOfficeTableContent;
  timeFrameContent: HomeOfficeTimeFrameContent;
  title: string;
  totalAccountsOpenedForDate: string;
  totalAumForDate: string;
  totalItemsLabel: string;
}

export interface HomeOfficeTableData {
  accountsData: {
    activeAsOfDate?: string;
    closedAsOfDate?: string;
    closedInPeriod?: string;
    inProgressAsOfDate?: string;
    openedInPeriod?: string;
  };
  advisor: {
    id: string;
    name: string;
    repCode: string;
  };
  aum: {
    change?: string;
    isPositive?: boolean;
    value: string;
  };
  contactData: {
    email: string;
    phoneNumber: string;
  };
  id: string;
}

export interface HomeOfficeData {
  appliedFilters: SelectedFilters;
  content: HomeOfficeContent;
  downloadLoading: boolean;
  filterConfig: FilterConfig[];
  filtersPopupOpen: boolean;
  homeOfficeTableItems: TableData[];
  onAppliedFiltersSelect: (filterKey?: string, selectedOption?: string) => void;
  onDownloadCSV: () => void;
  onFilterChange: (selectedFilters: SelectedFilters) => void;
  onSearchOptionSelect: (selectedSearchOption: SearchOption) => void;
  onSearchTermChange: (searchTerm: string) => void;
  page: number;
  paginationContext: PaginationContext;
  partnerDataLoading: boolean;
  searchError: boolean;
  searchOptions: SearchOption[];
  searchOptionsLoading: boolean;
  sortConfig: SortConfig;
  tableDataError?: Error;
  tableDataLoading: boolean;
  timeFrameValues: HomeOfficeTimeFrameValues;
  totalAccountsOpened: string;
  totalAccountsOpenedRteParams: {
    config: Record<string, ReactNode>;
    label: string;
  };
  totalAum?: {
    change?: string;
    isPositive?: boolean;
    value: string;
  };
  totalAumRteParams: {
    config: Record<string, ReactNode>;
    label: string;
  };
  totalPages: number;
  updateOpenFiltersPopup: (value: boolean) => void;
  updatePage: (page: number) => void;
  updateTimeFrameValues: (newTimeFilterValues: HomeOfficeTimeFrameValues) => void;
  view: HomeOfficeView;
}

export enum HomeOfficeContentKeys {
  CSV_CHANGE_IN_AUM = 'csv_change_in_aum',
  CSV_EMAIL = 'csv_email',
  CSV_PHONE = 'csv_phone',
  CSV_REP_CODE = 'csv_rep_code',
  DOWNLOAD_CSV_LABEL = 'download_csv_label',
  RECORDS_PER_PAGE_LABEL = 'records_per_page_label',
  SEARCH_NO_RESULTS = 'search_no_results',
  SEARCH_PLACEHOLDER = 'search_placeholder',
  SEARCH_REP_PREFIX = 'search_rep_prefix',
  TABLE_COLUMN_ACCOUNTS_ACTIVE_AS_OF_DATE = 'table_column_accounts_active_as_of_date',
  TABLE_COLUMN_ACCOUNTS_CLOSED_AS_OF_DATE = 'table_column_accounts_closed_as_of_date',
  TABLE_COLUMN_ACCOUNTS_CLOSED_IN_PERIOD = 'table_column_accounts_closed_in_period',
  TABLE_COLUMN_ACCOUNTS_IN_PROGRESS_AS_OF_DATE = 'table_column_accounts_in_progress_as_of_date',
  TABLE_COLUMN_ACCOUNTS_OPENED_IN_PERIOD = 'table_column_accounts_opened_in_period',
  TABLE_COLUMN_ADVISOR_NAME = 'table_column_advisor_name',
  TABLE_COLUMN_AUM_AS_OF_DATE = 'table_column_aum_as_of_date',
  TABLE_COLUMN_AUM_FOR_PERIOD = 'table_column_aum_for_period',
  TABLE_COLUMN_CONTACT_INFORMATION = 'table_column_contact_information',
  TABLE_FILTER_APPLY_TEXT = 'table_filter_apply_text',
  TABLE_FILTER_BUTTON_TEXT = 'table_filter_button_text',
  TABLE_FILTER_ERROR_MESSAGE = 'table_filter_error_message',
  TABLE_FILTER_MAX_VALUE_TEXT = 'table_filter_max_value_text',
  TABLE_FILTER_MIN_VALUE_TEXT = 'table_filter_min_value_text',
  TABLE_FILTER_RESET_ALL_TEXT = 'table_filter_reset_all_text',
  TABLE_NULL_STATE_MESSAGE = 'table_null_state_message',
  TABLE_REP_CODE_LABEL = 'table_rep_code_label',
  TIME_FRAME_DROPDOWN_PLACEHOLDER_MONTH = 'time_frame_dropdown_placeholder_month',
  TIME_FRAME_DROPDOWN_PLACEHOLDER_QUARTER = 'time_frame_dropdown_placeholder_quarter',
  TIME_FRAME_DROPDOWN_PLACEHOLDER_YEAR = 'time_frame_dropdown_placeholder_year',
  TIME_FRAME_LABEL = 'time_frame_label',
  TIME_FRAME_LABEL_DATE = 'time_frame_label_date',
  TIME_FRAME_LABEL_MONTH = 'time_frame_label_month',
  TIME_FRAME_LABEL_QUARTER = 'time_frame_label_quarter',
  TIME_FRAME_LABEL_YEAR = 'time_frame_label_year',
  TIME_FRAME_OPTION_DATE = 'time_frame_option_date',
  TIME_FRAME_OPTION_MONTH = 'time_frame_option_month',
  TIME_FRAME_OPTION_QUARTER = 'time_frame_option_quarter',
  TIME_FRAME_OPTION_YEAR = 'time_frame_option_year',
  TITLE = 'title',
  TOTAL_ACCOUNTS_OPENED_FOR_DATE = 'total_accounts_opened_for_date',
  TOTAL_AUM_FOR_DATE = 'total_aum_for_date',
  TOTAL_ITEMS_LABEL = 'total_items_label',
}

export enum HomeOfficeFilterOptions {
  ACCOUNTS_ACTIVE_AS_OF_DATE = 'ACCOUNTS_ACTIVE_AS_OF_DATE',
  ACCOUNTS_CLOSED_AS_OF_DATE = 'ACCOUNTS_CLOSED_AS_OF_DATE',
  ACCOUNTS_CLOSED_IN_PERIOD = 'ACCOUNTS_CLOSED_IN_PERIOD',
  ACCOUNTS_IN_PROGRESS_AS_OF_DATE = 'ACCOUNTS_IN_PROGRESS_AS_OF_DATE',
  ACCOUNTS_OPENED_IN_PERIOD = 'ACCOUNTS_OPENED_IN_PERIOD',
  AUM_AS_OF_DATE = 'AUM_AS_OF_DATE',
  AUM_FOR_PERIOD = 'AUM_FOR_PERIOD',
}

export type HomeOfficeFilterValues = Partial<Record<HomeOfficeFilterOptions, NumberRange>>;

export interface HomeOfficeTimeFrameContent {
  labels: HomeOfficeTimeFrameLabels;
  placeHolders: {
    month: string;
    quarter: string;
    year: string;
  };
  timeFrameLabel: string;
  timePeriodOptionLabels: HomeOfficeTimeFrameLabels;
}

export type HomeOfficeTimeFrameLabels = Record<HomeOfficeTimeFrameOptions, string>;

export enum HomeOfficeTimeFrameOptions {
  date = 'date',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

export interface HomeOfficeTimeFrameValues {
  endDate: Date;
  selectedTimeFrame: HomeOfficeTimeFrameOptions;
  startDate: Date;
}

export interface SearchOption {
  advisorName: string;
  advisorPartyId: string;
  advisorRepCode: string;
  id?: string;
  label?: string;
}
