import { useMemo } from 'react';

import { useGetSessionTimeoutMessageContent } from './contentstack';

import { AsyncResult } from '~/utils/types';

export interface Data {
  ctas: {
    continue: string;
    navigate: string;
  };
  expiredMessage: string;
  expiredSubMessage: string;
  heading: string;
  subHeading: string;
}

export const useSessionTimeoutMessageContent = (
  variables: Parameters<typeof useGetSessionTimeoutMessageContent>[0],
): AsyncResult<Data> => {
  const {
    data: contentData,
    loading: contentLoading,
    error: contentError,
  } = useGetSessionTimeoutMessageContent(variables);

  const data = useMemo(() => {
    if (contentLoading) {
      return undefined;
    }
    const response = contentData?.all_session_timeout_message?.items?.[0];
    return {
      ctas: {
        continue: response?.continue_session_message ?? '',
        navigate: response?.navigate_message ?? '',
      },
      heading: response?.message ?? '',
      subHeading: response?.sub_message ?? '',
      expiredMessage: response?.expired_message ?? '',
      expiredSubMessage: response?.expired_sub_message ?? '',
    };
  }, [contentData?.all_session_timeout_message?.items, contentLoading]);

  return {
    data,
    error: contentError,
    loading: contentLoading,
  };
};
