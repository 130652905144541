import { useEffect, useState } from 'react';

import { useGetErrorMessagesContent } from './contentstack';

import { ContentOptions } from '~/utils/contentstack/src/types';
import { AsyncResult } from '~/utils/types';

export interface ErrorMessagesData {
  alert_message?: string;
  error_component_message?: string;
  error_component_sub_message?: string;
  error_component_unauthorised_message?: string;
  error_component_unauthorised_sub_message?: string;
  error_details_network_message?: string;
  error_image_url?: string;
  redirect_cta?: {
    label?: string;
    link?: string;
  };
  redirect_cta_unauthorised?: {
    label?: string;
    link?: string;
  };
}

export const useGetErrorMessagesData = (contentOptions?: ContentOptions): AsyncResult<ErrorMessagesData> => {
  const [state, setState] = useState<AsyncResult<ErrorMessagesData>>({ loading: true });

  const {
    data: contentData,
    loading: contentLoading,
    error: contentError,
  } = useGetErrorMessagesContent({
    variables: contentOptions,
    skip: !contentOptions,
  });

  useEffect(() => {
    if (!contentLoading) {
      if (contentError) {
        setState({
          loading: false,
          error: contentError,
        });
        return;
      }

      const response = contentData?.all_error_messages?.items?.[0];
      const tempData: ErrorMessagesData = {
        alert_message: response?.alert_message ?? undefined,
        error_details_network_message: response?.error_details_network_message ?? undefined,
        error_component_message: response?.error_component_message ?? undefined,
        error_component_sub_message: response?.error_component_sub_message ?? undefined,
        error_component_unauthorised_message: response?.error_component_unauthorised_message ?? undefined,
        error_component_unauthorised_sub_message: response?.error_component_unauthorised_sub_message ?? undefined,
        error_image_url: response?.error_imageConnection?.edges?.[0]?.node?.url ?? undefined,
        redirect_cta: response?.redirect_cta
          ? {
              label: response?.redirect_cta?.label ?? undefined,
              link: response?.redirect_cta?.link ?? undefined,
            }
          : undefined,
        redirect_cta_unauthorised: response?.redirect_cta_unauthorised
          ? {
              label: response?.redirect_cta_unauthorised?.label ?? undefined,
              link: response?.redirect_cta_unauthorised?.link ?? undefined,
            }
          : undefined,
      };

      setState({
        data: tempData,
        loading: false,
      });
    }
  }, [contentData, contentError, contentLoading]);

  return state;
};
