import uniqueId from 'lodash.uniqueid';
import React, { ComponentProps, FC, ReactNode, useMemo } from 'react';

import { ErrorComponent } from '~/components/ErrorComponent';
import { CircularProgress } from '~/components/ui/CircularProgress';
import { Drawer } from '~/components/ui/Drawer';
import {
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '~/components/ui/mui';
import { ContentOptions } from '~/utils/contentstack';
import { useIsMediumScreen } from '~/utils/responsiveness';

// contentOptions prop is required when error prop is used.
type ModalErrorProps =
  | { contentOptions: ContentOptions; error: Error }
  | { contentOptions?: ContentOptions; error?: never };
export type Props = Omit<ComponentProps<typeof Dialog>, 'onClose' | 'title'> &
  ModalErrorProps & {
    actions?: ReactNode;
    content?: ReactNode;
    dataQa?: string;
    dividers?: boolean;
    hideCloseIcon?: boolean;
    hideHeader?: boolean;
    loading?: boolean;
    onClose?: ComponentProps<typeof Dialog>['onClose'] | (() => void);
    title?: ReactNode;
  };

/**
 * @param {Props} props Modal props
 * @param props.dataQa
 * @param props.actions Modal CTAs to be rendered on the bottom.
 * @param props.content
 * @param props.contentOptions Required when error prop is used for the Error message
 * @param props.dividers
 * @param props.error Critical error resulting rendering error message while neither content nor actions are rendered.
 *    Only the X close button shall be rendered to close the modal.
 * @param props.loading When true, LoadingSpinner is rendered before modal content is rendered.
 * @param props.open
 * @param props.onClose
 * @param props.fullWidth
 * @param props.fullScreen
 * @param props.title
 * @param props.hideHeader True to hide Modal title.
 *    The header will still be rendered with X close while loading or in the event of error to allow user to close.
 * @param props.dialogProps
 */
export const Modal: FC<Props> = ({
  dataQa = 'modal',
  actions,
  content,
  contentOptions,
  dividers = true,
  error,
  loading,
  open,
  onClose,
  fullWidth = true,
  fullScreen,
  title: baseTitle,
  hideCloseIcon = false,
  hideHeader = false,
  ...dialogProps
}) => {
  const titleId = uniqueId(`${dataQa}-title-id`);
  const {
    sfModal: { styles: sfModalStyles },
  } = useTheme();
  const isMobile = useIsMediumScreen();
  const isLoadingOrError = !!error || !!loading;
  const showCloseCta = !!onClose && (isLoadingOrError || !hideHeader) && !hideCloseIcon;
  const title = hideHeader ? undefined : baseTitle;

  const body = useMemo(
    () => (
      <>
        {loading && !error && <CircularProgress />}
        {error && <ErrorComponent contentOptions={contentOptions} error={error} />}
        {!isLoadingOrError && content}
      </>
    ),
    [content, contentOptions, error, isLoadingOrError, loading],
  );

  if (isMobile && !fullScreen) {
    return (
      <Drawer
        actions={isLoadingOrError ? undefined : actions}
        aria-labelledby={titleId}
        dataQa={`${dataQa}-drawer`}
        onClose={showCloseCta ? onClose : undefined}
        open={open}
        title={title}
      >
        {body}
      </Drawer>
    );
  }

  return (
    <Dialog
      {...dialogProps}
      aria-labelledby={titleId}
      aria-modal="true"
      data-qa={`${dataQa}-dialog`}
      disableEscapeKeyDown
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      onClose={onClose}
      open={open}
      role="dialog"
    >
      {!loading && (title || showCloseCta) && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent={title ? 'space-between' : 'flex-end'}
          sx={sfModalStyles.dialogTitle}
        >
          {title && (
            <DialogTitle data-qa={`${dataQa}-title`} id={titleId} sx={{ flex: 'auto' }}>
              {title}
            </DialogTitle>
          )}

          {showCloseCta && (
            <IconButton
              aria-label="close modal"
              autoFocus
              data-qa={`${dataQa}-close`}
              onClick={onClose as () => void}
              sx={{ mr: 1 }}
            >
              <CloseIcon fontSize="small" sx={{ color: 'text.secondary' }} />
            </IconButton>
          )}
        </Stack>
      )}
      <DialogContent data-qa={`${dataQa}-content`} dividers={dividers} sx={[!!loading && { textAlign: 'center' }]}>
        {body}
      </DialogContent>
      {actions && !isLoadingOrError && (
        <DialogActions data-qa={`${dataQa}-actions`} sx={sfModalStyles.dialogActions}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};
